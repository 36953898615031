// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gifts {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font: var(--textsFont);
  }
  
  .gifts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    justify-content: center;
    gap: 1rem;
    width: 70%;
    text-align: center;
    align-items: center;
    position: relative;
  }
  h1 {
    text-align: center;
  }

  .gifts_description {
    text-align: center;
    font-size: 1.5rem;
    font-style: italic;
    margin-bottom: 1rem;
    width: 70%;
  }
`, "",{"version":3,"sources":["webpack://./src/Gifts.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,4DAA4D;IAC5D,uBAAuB;IACvB,SAAS;IACT,UAAU;IACV,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,UAAU;EACZ","sourcesContent":[".gifts {\n    padding: 2rem;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    font: var(--textsFont);\n  }\n  \n  .gifts-grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n    justify-content: center;\n    gap: 1rem;\n    width: 70%;\n    text-align: center;\n    align-items: center;\n    position: relative;\n  }\n  h1 {\n    text-align: center;\n  }\n\n  .gifts_description {\n    text-align: center;\n    font-size: 1.5rem;\n    font-style: italic;\n    margin-bottom: 1rem;\n    width: 70%;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
