import React from 'react';
import { useTranslation } from 'react-i18next';

function RSVP() {
  const { t } = useTranslation();

  return (
    <div id="rsvp" className="rsvp">
      <h1>{t('rsvp')}</h1>
      <p>{t('rsvp_message')}</p>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc7RZzw241HsnRacV9p8QTFSYU9M6xwxWtXNOSNMlwVTxLTVQ/viewform?embedded=true" width="100%" height="419" frameborder="0" marginheight="0" marginwidth="0" >Načítava sa…</iframe>
    </div>
  );
}

export default RSVP;
