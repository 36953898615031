import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Admin from './Admin';

ReactDOM.render(

  <I18nextProvider i18n={i18n}>
    
    
    <Router>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/" component={App} />
      </Switch>
    </Router>
  </I18nextProvider>,
  document.getElementById('root')
);
