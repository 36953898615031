import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import enTranslation from './locales/en/translation.json';
import skTranslation from './locales/sk/translation.json';

// Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      sk: {
        translation: skTranslation,
      },
    },
    lng: 'sk', // Default language
    fallbackLng: 'sk',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
