import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Login from './Login';

const Admin = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [gifts, setGifts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [newGift, setNewGift] = useState({
    name: '',
    englishName: '',
    price: '',
    amountPaid: '',
    picture: ''
  });

  useEffect(() => {
    if (isAuthenticated) {
      fetchGifts();
      fetchPayments();
    }
  }, [isAuthenticated]);

  const fetchGifts = async () => {
    try {
      const response = await axios.get('http://localhost:3001/gifts');
      setGifts(response.data);
    } catch (error) {
      console.error('Error fetching gifts:', error);
    }
  };

  const fetchPayments = async () => {
    try {
      const response = await axios.get('http://localhost:3001/payments');
      setPayments(response.data);
    } catch (error) {
      console.error('Error fetching payments:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGift({ ...newGift, [name]: value });
  };

  const handleAddGift = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:3001/gifts', newGift);
      setNewGift({ name: '', englishName: '', price: '', amountPaid: '', picture: '' });
      fetchGifts(); // Refresh the list of gifts
    } catch (error) {
      console.error('Error adding gift:', error);
    }
  };

  const handleDeleteGift = async (giftId) => {
    try {
      await axios.delete(`http://localhost:3001/gifts/${giftId}`);
      fetchGifts(); // Refresh the list of gifts
    } catch (error) {
      console.error('Error deleting gift:', error);
    }
  };

  if (!isAuthenticated) {
    return <Login onLogin={setIsAuthenticated} />;
  }

  return (
    <div className='admin'>
      <h1>Admin Page</h1>
      <h2>Add New Gift</h2>
      <form onSubmit={handleAddGift}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={newGift.name}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>English name:</label>
          <input
            type="text"
            name="englishName"
            value={newGift.englishName}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Price:</label>
          <input
            type="number"
            name="price"
            value={newGift.price}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Amount paid:</label>
          <input
            type="number"
            name="amountPaid"
            value={newGift.amountPaid}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Picture URL:</label>
          <input
            type="text"
            name="picture"
            value={newGift.picture}
            onChange={handleInputChange}
          />
        </div>
        <button type="submit">Add Gift</button>
      </form>

      <h2>All Gifts</h2>
      <ul>
        {gifts.map((gift) => (
          <li key={gift.id}>
            {gift.name} - {gift.price}€
            <button onClick={() => handleDeleteGift(gift.id)}>Delete</button>
          </li>
        ))}
      </ul>

      <h2>All Payments</h2>
      <ul>
        {payments.map((payment) => (
          <li key={payment.id}>
            {payment.donorName} paid {payment.amount}€ for gift ID {payment.giftId}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Admin;
