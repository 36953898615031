import React from 'react';
import { useTranslation } from 'react-i18next';

function Welcome() {
  const { t } = useTranslation();

  return (
    <div id="welcome" className="welcome">
      <h1>{t('welcome')}</h1>
      {/* add here two columns. First one will contain When and seconf Where */}
      <div className="welcome-grid">
        <div className="welcome-grid-item">
          <h2>{t('when')}</h2>
          <h3>{t('when_date')}</h3>

        </div>
        <div className="welcome-grid-item">
          <h2>{t('where')}</h2>
          <h3>{t('where_location')}</h3>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
