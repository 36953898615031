import React from 'react';
import { useTranslation } from 'react-i18next';

function Photos() {
  const { t } = useTranslation();

  return (
    <div id="photos" className="photos">
      <h1>{t('photos')}</h1>
      <p>{t('photos_message')}</p>
      {/* Add photo gallery here */}
    </div>
  );
}

export default Photos;
