import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Gift from './Gift';
import './Gifts.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';

function Gifts() {
  const { t } = useTranslation();
  const [gifts, setGifts] = useState([]);

  useEffect(() => {
    axios.get('http://localhost:3001/gifts')
      .then(response => {
        setGifts(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the gifts!', error);
      });
  }, []);

  return (
    <div id="gifts" className="gifts">
      <h1>{t('wedding_gifts')}</h1>
      <div className='gifts_description'>{t('wedding_gifts_description')}</div>
      <div className="gifts-grid">
        {gifts.map(gift => (
          <Gift key={gift.id} gift={gift} />
        ))}
      </div>
    </div>
  );
}

export default Gifts;
